import { Route } from '@angular/router'
import { AuthGuard } from '@core/guards/auth.guard'
import { superAdminGuard } from '@core/guards/super-admin.guard'
import { PageLayout } from '@modules/layout/page-layout.enum'
import { setLayout } from '@modules/layout/set-layout.resolver'

export type AdminRoutes = {
    index: Route
    feedbacks: Route
    profile: Route
    users: Route
    plans: Route
    createReleaseNote: Route
    editReleaseNote: Route
    organizations: Route
    releaseNotes: Route
    cerpBoard: Route
}

export function getAdminRoutes(): AdminRoutes {
    return {
        index: {
            path: 'admin',
            title: 'Admin Dashboard',
            resolve: { layout: setLayout(PageLayout.Admin) },
            canActivate: [AuthGuard, superAdminGuard()],
            loadComponent: () =>
                import('@pages/admin/admin-home/admin-home.component').then(
                    (m) => m.AdminHomeComponent,
                ),
        },
        feedbacks: {
            path: 'admin/feedbacks',
            title: 'Admin - Feedback',
            resolve: { layout: setLayout(PageLayout.Admin) },
            canActivate: [AuthGuard, superAdminGuard()],
            loadComponent: () =>
                import('./admin-feedback-management/admin-feedback-management.component').then(
                    (m) => m.AdminFeedbackManagementComponent,
                ),
        },
        profile: {
            path: 'admin/profile',
            title: 'Admin - Profile',
            resolve: { layout: setLayout(PageLayout.Admin) },
            canActivate: [AuthGuard, superAdminGuard()],
            // TODO: replace with correct component
            loadComponent: () =>
                import('@pages/dashboard/dashboard-home/dashboard-home.component').then(
                    (m) => m.DashboardHomeComponent,
                ),
        },
        users: {
            path: 'admin/users',
            title: 'Admin - Users',
            resolve: { layout: setLayout(PageLayout.Admin) },
            canActivate: [AuthGuard, superAdminGuard()],
            loadComponent: () =>
                import('@pages/admin/admin-user-management/admin-user-management.component').then(
                    (m) => m.AdminUserManagementComponent,
                ),
        },
        plans: {
            path: 'admin/plans',
            title: 'Admin Plans',
            resolve: { layout: setLayout(PageLayout.Admin) },
            canActivate: [AuthGuard, superAdminGuard()],
            loadComponent: () =>
                import('@pages/admin/plans/plans.component').then((m) => m.PlansComponent),
        },
        organizations: {
            path: 'admin/organization',
            title: 'Admin Organizations',
            resolve: { layout: setLayout(PageLayout.Admin) },
            canActivate: [AuthGuard, superAdminGuard()],
            loadComponent: () =>
                import(
                    '@pages/admin/admin-organization-management/admin-organization-management.component'
                ).then((m) => m.AdminOrganizationManagementComponent),
        },
        createReleaseNote: {
            path: 'admin/release-notes/create',
            title: 'Admin Updates',
            resolve: { layout: setLayout(PageLayout.Admin) },
            canActivate: [AuthGuard, superAdminGuard()],
            loadComponent: () =>
                import('@pages/admin/application-updates/application-updates.component').then(
                    (m) => m.ApplicationUpdatesComponent,
                ),
        },
        editReleaseNote: {
            path: 'admin/release-notes/:id/edit',
            title: 'Admin Updates',
            resolve: { layout: setLayout(PageLayout.Admin) },
            canActivate: [AuthGuard, superAdminGuard()],
            loadComponent: () =>
                import('@pages/admin/application-updates/application-updates.component').then(
                    (m) => m.ApplicationUpdatesComponent,
                ),
        },
        releaseNotes: {
            path: 'admin/release-notes',
            title: 'Admin - Release Notes',
            resolve: { layout: setLayout(PageLayout.Admin) },
            canActivate: [AuthGuard, superAdminGuard()],
            loadComponent: () =>
                import(
                    '@modules/update/components/release-note-list/release-note-list.component'
                ).then((m) => m.ReleaseNoteListComponent),
        },
        cerpBoard: {
            path: 'admin/cerp-board',
            title: 'Admin - Cerp Board',
            resolve: { layout: setLayout(PageLayout.Admin) },
            canActivate: [AuthGuard, superAdminGuard()],
            loadComponent: () =>
                import('@pages/admin/admin-cerp-board/admin-cerp-board.component').then(
                    (m) => m.AdminCerpBoardComponent,
                ),
        },
    }
}
